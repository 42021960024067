<template>
	<div class="grid grid-cols-1 gap-2 text-center justify-items-center md:justify-items-start md:text-left">
		<a
			v-if="hasJobsLink"
			v-track-event:external.click="externalLinkClickTrackingData(jobsLink)"
			:href="jobsLink"
			target="_blank"
			class="flex max-w-[150px] h-10 px-4 mr-2 text-xs text-center text-white transition-all duration-100 ease-in-out bg-transparent border-2 border-white rounded-lg md:w-auto flex-col order-last xs:px-2 xl:hover:bg-black/40 xl:hover:white xl:hover:shadow-lg"
		>
			<span class="m-auto">
				View Job Openings
			</span>
		</a>
		<section
			v-if="hasLinks"
			class="flex flex-col items-center pb-2 space-y-3 md:items-start md:pb-0"
		>
			<a
				v-if="showWebsite"
				v-track-event:external.click="externalLinkClickTrackingData(websiteUrl)"
				:href="websiteUrl"
				target="_blank"
				class="flex p-1 -ml-0.5 text-xs text-center transition-all duration-100 ease-in-out rounded-md md:text-left xl:hover:bg-black/40"
			>
				Visit us:
				<ArrowDoor class="ml-2 text-white" />
			</a>
			<ul class="flex items-center space-x-2">
				<li
					v-for="(social, index) in newLinks"
					:key="index"
					class="flex items-center"
				>
					<a
						v-track-event:external.click="externalLinkClickTrackingData(social.url)"
						class="flex p-1 transition-all duration-100 ease-in-out rounded-md xl:hover:bg-black/40"
						:href="social.url"
						target="_blank"
					>
						<img
							:src="social.icon"
							:alt="social.alt"
							class="w-5 h-5"
						>
					</a>
				</li>
			</ul>
		</section>
	</div>
</template>

<script async>
import ArrowDoor from '@/components/icons/ArrowDoor.vue'
import PLATFORMS from '@/constants/business/social-media.js'

export default {
	components: {
		ArrowDoor
	},
	props: {
		chainId: {
			type: String,
			required: true
		},
		links: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			website: {
				name: 'Website',
				icon: require('@/assets/icons/website-white.svg'),
				alt: 'Website Icon - Where\'s Weed'
			}
		}
	},
	computed: {
		websiteUrlAlreadyFormatted() { // #NOTE: apparently api gives many different formats for website url, and not all work...
			return this.links?.website?.slice(0, 4) === 'http' || this.links?.website?.slice(0, 2) === '//'
		},
		websiteUrl() {
			if (this.links?.website) {
				if (this.websiteUrlAlreadyFormatted) {
					return this.links.website
				} else {
					return `//${this.links.website}`
				}
			}
			return ''
		},
		hasLinks() {
			return !!this.newLinks.length
		},
		jobsLink() {
			return this.links.jobsLink
		},
		hasJobsLink() {
			return !!this.jobsLink.length
		},
		showWebsite() {
			return !!this.websiteUrl.length
		},
		newLinks() {
			const socials = this.links.socials || {}
			const socialLinks = Object.keys(socials).filter(key =>
				typeof socials[key] === 'string' &&
				socials[key].length &&
				key !== '__typename'
			).map(key => {
				return {
					name: PLATFORMS[key].name,
					alt: PLATFORMS[key].alt,
					url: PLATFORMS[key].url + socials[key],
					icon: PLATFORMS[key].iconWhite
				}
			})
			return socialLinks
		}
	},
	methods: {
		externalLinkClickTrackingData(destinationUrl) {
			return {
				chainId: this.chainId,
				destinationUrl
			}
		}
	}
}
</script>
