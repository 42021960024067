<template>
	<div
		id="scrollArea"
		class="relative grid w-full max-w-4xl grid-cols-1 m-auto"
	>
		<ChainIntroAccordian
			v-if="introText"
			:intro-text="introText"
		/>
		<section
			v-if="hasStateRows"
			class="grid justify-center grid-cols-1 divide-y-8 divide-white"
		>
			<ChainsStateRow
				v-for="state in stateRows"
				:id="state.name"
				:key="state.id"
				:category="state"
				:number-of-items-on-first-load="initialCardAmount"
				:items-per-page="perPage"
				:loading="loading"
				@accordion-click="handleAccordionClick"
				@next-page="handleNextPage"
			/>
		</section>
		<ChainFaq
			v-if="hasFAQs"
			:faq="faq"
			:faq-heading="faqHeading"
		/>
		<Transition name="fade">
			<button
				v-if="accordionInViewExpanded && scrollWithinBounds"
				class="md:hidden fixed flex items-center justify-center flex-nowrap h-8 px-3 whitespace-nowrap font-semibold text-center text-white -translate-x-1/2 -translate-y-1/2 bg-green-500 rounded-full shadow-lg top-[124px] left-1/2 shadow-green-500/40"
				@click="handleFloatingCollapseButtonClick"
			>
				Collapse {{ accordionInView }} Locations
			</button>
		</Transition>
	</div>
</template>

<script async>
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		ChainIntroAccordian: () => import('@/components/chains/ChainIntroAccordian.vue'),
		ChainFaq: () => import('@/components/chains/ChainFaq.vue'),
		ChainsStateRow: () => import('@/components/chains/ChainStateRow.vue')
	},
	props: {
		chain: {
			type: Object,
			required: true
		},
		locations: {
			type: Array,
			required: true
		},
		perPage: {
			type: Number,
			default: 5
		},
		loading: {
			type: Number,
			default: 0
		}
	},
	emits: [ 'fetch-more' ],
	data() {
		return {
			observer: null,
			accordionInView: '',
			elementInView: null,
			elementBounds: null,
			scrollWithinBounds: false,
			tempTargets: [],
			stateRows: []
		}
	},
	computed: {
		introText() {
			return this.chain?.about
		},
		hasFAQs() {
			return !!this.faq?.length
		},
		faq() {
			return this.chain?.faq
		},
		faqHeading() {
			return 'Frequently asked questions about cannabis at ' + this.chain?.hero?.h1
		},
		browserSupportsIO() {
			return window && 'IntersectionObserver' in window
		},
		mediaMatch() {
			return this.$store.state.mediaMatch
		},
		initialCardAmount() {
			const isTablet = this.mediaMatch === 'md'
			return isTablet ? 3 : 4
		},
		hasStateRows() {
			return !!this.stateRows?.length
		},
		accordionInViewExpanded() {
			return this.stateRows?.find((state) => state.name === this.accordionInView)?.expanded
		}
	},
	watch: {
		locations: {
			handler() {
				if (this.locations.length) {
					const tempStates = []
					this.locations.forEach((location, index) => { // build and map state rows
						tempStates.push({
							expanded: this.stateRows?.[index]?.expanded || false,
							page: this.stateRows?.[index]?.page || 1,
							totalCount: location?.totalLocations,
							listings: location?.listings,
							id: index,
							name: location?.state
						})
					})
					this.stateRows = tempStates
				}
			},
			immediate: true,
			deep: true
		}
	},
	mounted () {
		if (window) {
			window.addEventListener('scroll', this.handleScroll)
		}
	},
	destroyed () {
		if (window) {
			window.removeEventListener('scroll', this.handleScroll)
		}
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},
	methods: {
		handleScroll () {
			if (this.accordionInView && this.accordionInViewExpanded) {
				this.updateElementBounds()
			}
		},
		updateElementBounds() {
			this.elementBounds = {
				name: this.accordionInView,
				top: this.elementInView.getBoundingClientRect().top,
				bottom: this.elementInView.getBoundingClientRect().bottom
			}

			if (this.elementBounds.top < 0) { // check if top is above viewport
				if (this.elementBounds.bottom < 0) { // check if bottom is above viewport
					this.scrollWithinBounds = false
				} else {
					this.scrollWithinBounds = true
				}
			} else {
				this.scrollWithinBounds = false
			}
		},
		observeRows() {
			this.$nextTick(() => {
				if (this.browserSupportsIO && this.hasStateRows) {
					const options = {
						threshold: Array(11).fill().map((_, i) => i * 0.1)
					} // #NOTE: 11 thresholds from 0 to 1 at 0.1 (10% intersection) intervals

					const scrollTargets = document.getElementsByClassName('observed')
					this.observer = new IntersectionObserver(entries => {
						entries.forEach(entry => {
							if (entry.isIntersecting) {
								const targetId = entry.target.id
								const targetState = targetId.split('-')[1]
								const container = document.getElementById(targetState)
								this.accordionInView = targetState
								this.elementInView = container
								this.elementBounds = {
									name: targetState,
									top: container.getBoundingClientRect().top,
									bottom: container.getBoundingClientRect().bottom
								}
							}
						},
						options)
					})
					for (const element of scrollTargets) {
						this.observer.observe(element)
					}
				}
			})
		},
		handleFloatingCollapseButtonClick() {
			this.stateRows.find((state) => state.name === this.accordionInView).expanded = false
		},
		handleAccordionClick(stateId) {
			this.toggleAccordion(stateId)
			this.$nextTick(() => {
				try {
					this.observeRows()
				} catch (e) {
					logError(e)
				}
			})
		},
		toggleAccordion(stateId) {
			const row = this.getRowById(stateId)
			row.expanded = !row.expanded
			if (row.expanded) this.handleExpanded(row)
		},
		getRowById(stateId) {
			return this.stateRows.find(row => row.id === stateId)
		},
		handleExpanded(row) {
			const isFirstPage = row.page === 1
			if (isFirstPage) this.getMoreCards(row)
		},
		getMoreCards(row) {
			const numberOfCards = row.listings.length
			const hasMoreCards = numberOfCards < row.totalCount

			if (hasMoreCards) {
				this.stateRows[row.id].page++
				this.$emit('fetch-more', row.name, numberOfCards)
			}
		},
		handleNextPage(stateId) {
			const row = this.getRowById(stateId)
			this.getMoreCards(row)
		}
	}
}
</script>
