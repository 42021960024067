<template>
	<div
		:style="heroBackgroundImgStyle"
		class="flex items-center text-left text-white bg-center bg-cover xs:min-h-100 min-h-[480px] md:min-h-72"
	>
		<!-- use this img tag as a helper to check if the image is valid, then swap hero image for fallback if it errors -->
		<img
			v-if="useCustomHeroBackgroundImage"
			class="hidden"
			height="480"
			width="1920"
			alt="hidden hero image"
			:src="builtHeroImage"
			@error="setHeroBackgroundImageToFallback"
		>
		<!-- the above would have been much simpler to natively add a fallback if CSS4 image() was available https://www.w3.org/TR/css-images-4/#image-notation https://caniuse.com/?search=image() -->
		<div class="flex flex-col w-full h-full max-w-4xl px-12 py-8 mx-auto my-0 md:px-4 xl:px-1.5 xs:px-4 md:flex-row md:min-h-52">
			<section class="w-[200px] h-[200px] mx-auto md:mx-0">
				<WwImage
					:src="hero.logo"
					:width="200"
					:height="200"
					:alt="hero.h1 + ' Thumbnail Image'"
					object-cover
					:image-path="CHAIN_IMAGES"
					:lazy="false"
					fetch-priority="high"
					classes="border border-gray-200"
				/>
			</section>

			<section class="max-w-[280px] md:max-w-none flex flex-col w-full h-full px-0 pt-4 md:pt-0 mx-auto my-auto md:px-4 md:m-0 md:w-auto text-center md:text-left">
				<div
					ref="h1"
					class="flex items-center w-full mb-2 md:block md:min-h-9"
				>
					<h1
						v-if="hasH1"
						class="w-full text-3xl font-bold xs:text-2xl"
					>
						{{ hero?.h1 }}
					</h1>
				</div>
				<h2
					v-if="hasH2"
					ref="h2"
					class="w-full mb-2 text-lg xs:text-base"
				>
					{{ hero?.h2 }}
				</h2>
				<ChainLinks
					v-if="hero.chainId"
					:links="links"
					:chain-id="hero.chainId"
				/>
			</section>
		</div>
	</div>
</template>

<script async>
import { mapMutations } from 'vuex'

import ChainLinks from '@/components/chains/ChainLinks.vue'
import WwImage from '@/components/multiUse/WwImage.vue'
import { CHAIN_IMAGES } from '@/constants/images/image-folder-paths.js'
import buildImageUrl from '@/utils/builders/buildImageUrl.js'

export default {
	components: {
		WwImage,
		ChainLinks
	},
	props: {
		hero: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			CHAIN_IMAGES,
			defaultHeroImage: require('@/assets/backgrounds/leaf-header-background.jpg'),
			useFallbackHeroImage: false
		}
	},
	computed: {
		hasHeroImage() {
			return !!this.hero?.coverImage?.length
		},
		builtHeroImage() {
			if (this.hasHeroImage) {
				return buildImageUrl({
					file: this.hero.coverImage, imagePath: CHAIN_IMAGES, full: true
				})
			}
			return ''
		},
		useCustomHeroBackgroundImage() {
			return this.hasHeroImage && !!this.builtHeroImage.length
		},
		heroBackgroundImgStyle() {
			return this.useCustomHeroBackgroundImage && !this.useFallbackHeroImage
				? { backgroundImage: `url(${this.builtHeroImage})` }
				: { backgroundImage: `url(${this.defaultHeroImage})` }
		},
		hasH1() {
			return this.hero?.h1?.length
		},
		hasH2() {
			return this.hero?.h2?.length
		},
		links() {
			return this.hero?.links
		},
		rating() {
			const roundedRatingString = parseFloat(this.hero?.rating).toFixed(1)
			return parseFloat(roundedRatingString) || 0
		},
		reviewCount() {
			return this.hero?.reviewCount || 0
		},
		hasReviews() {
			return this.reviewCount > 0
		},
		metaPreload() {
			return { rel: 'preload', href: this.builtHeroImage, as: 'image' }
		},
		starIcon() {
			return this.rating > 0 ? require('@/assets/icons/star-full-yellow.svg') : require('@/assets/icons/star-full-gray.svg')
		}
	},
	created() {
		if (this.useCustomHeroBackgroundImage) this.pushMetaLink(this.metaPreload)
	},
	methods: {
		...mapMutations([ 'pushMetaLink' ]),
		setHeroBackgroundImageToFallback() {
			this.useFallbackHeroImage = true
		}
	}
}
</script>
