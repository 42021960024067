export const PLATFORMS = {
	facebook: {
		value: 'facebook',
		name: 'Facebook',
		icon: require('@/assets/icons/facebook-color.svg'),
		iconWhite: require('@/assets/icons/facebook-white.svg'),
		alt: 'Facebook Link',
		url: '//www.facebook.com/'
	},
	twitter: {
		value: 'twitter',
		name: 'Twitter',
		icon: require('@/assets/icons/twitter-color.svg'),
		iconWhite: require('@/assets/icons/twitter-white.svg'),
		alt: 'Twitter Link',
		url: '//www.twitter.com/'
	},
	instagram: {
		value: 'instagram',
		name: 'Instagram',
		icon: require('@/assets/icons/instagram-color.svg'),
		iconWhite: require('@/assets/icons/instagram-white.svg'),
		alt: 'Instagram Link',
		url: '//www.instagram.com/'
	},
	pinterest: {
		value: 'pinterest',
		name: 'Pinterest',
		icon: require('@/assets/icons/pinterest-color.svg'),
		iconWhite: require('@/assets/icons/pinterest-white.svg'),
		alt: 'Pintrest Link',
		url: '//www.pintrest.com/'
	},
	linkedin: {
		value: 'linkedin',
		name: 'LinkedIn',
		icon: require('@/assets/icons/linkedin.svg'),
		iconWhite: require('@/assets/icons/linkedin-white.svg'),
		alt: 'LinkedIn Link',
		url: '//www.linkedin.com/company/' // TODO maybe this needs company vs not url i dunno
	}
}

export default PLATFORMS
