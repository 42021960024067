<template>
	<DefaultLayout>
		<div>
			<ChainHero :hero="hero" />
			<ChainPageContent
				:chain="chain || {}"
				:locations="locations || []"
				:per-page="PER_PAGE"
				:loading="loading"
				@fetch-more="handleFetchMore"
			/>
		</div>
	</DefaultLayout>
</template>

<script async>
import ChainHero from '@/components/chains/ChainHero.vue'
import ChainPageContent from '@/components/chains/ChainPageContent.vue'
import { GetChainPageData } from '@/gql/chains/queries/pages.gql'
import DefaultLayout from '@/layouts/default/Index.vue'
import { pageLevelGQLErrors } from '@/utils/error-handling.js'

const PER_PAGE = 5
const INITIAL_FETCH_LIMIT = 4
const SUBSEQUENT_FETCH_LIMIT = 20

export default {
	components: {
		DefaultLayout,
		ChainHero,
		ChainPageContent
	},
	metaInfo() {
		return {
			title: this.metaTitle,
			meta: [
				{ description: this.metaDescription }
			]
		}
	},
	data () {
		return {
			loading: 0,
			PER_PAGE
		}
	},
	apollo: {
		chain: {
			query: GetChainPageData,
			update(data) {
				if (data.auth) this.$store.commit('auth/setAuth', { auth: data.auth })
				if (data.chains?.length) {
					return {
						...data.chains[0]
					}
				}
				this.$store.commit('setError', { status: 404 })
				return {}
			},
			variables() {
				return {
					seoUrl: this.$route.params.chain,
					limit: INITIAL_FETCH_LIMIT,
					offset: 0
				}
			},
			error(error) {
				pageLevelGQLErrors(error, this.$store)
			},
			errorPolicy: 'none' // bypass setting the 404 (the update hook) if no results are returned
		}
	},
	computed: {
		hero() {
			return {
				chainId: this.chain?.id?.toString(),
				h1: this.chain?.name,
				h2: this.chain?.metaDescription,
				coverImage: this.chain?.coverImage,
				logo: this.chain?.logo,
				rating: this.chain?.rating ?? 0,
				reviewCount: this.chain?.reviewCount ?? 0,
				links: {
					socials: {
						facebook: this.chain?.facebook,
						instagram: this.chain?.instagram,
						twitter: this.chain?.twitter,
						pinterest: this.chain?.pinterest,
						linkedin: this.chain?.linkedin
					},
					website: this.chain?.websiteUrl,
					jobsLink: this.chain?.jobBoard,
					phone: this.chain?.contact
				}
			}
		},
		locations() {
			return this.chain?.locations
		},
		metaTitle() {
			const shortName = this.chain?.shortName || this.chain?.name
			const numberOfListings = this.chain?.numberOfListings || 0
			const allLocations = 'in ' + this.chain?.locations?.map((location) => location?.state?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter?.toUpperCase()))?.join(', ') || ''
			return `${shortName} Marijuana Dispensaries with ${numberOfListings} Locations ${allLocations} | Where’s Weed`
		},
		metaDescription() {
			const services = [
				{ hasDelivery: 'Delivery' },
				{ hasPickup: 'In-store' },
				{ hasMedical: 'Medical' },
				{ hasRecreational: 'Recreational' }
			]
			const serviceTypes = {
				hasDelivery: false,
				hasPickup: false,
				hasMedical: false,
				hasRecreational: false
			}
			const chainName = this.chain?.name
			const first3States = this.chain?.locations?.map((location) => location?.state?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter?.toUpperCase()))?.slice(0, 3)?.join(', ') || ''
			const states = this.chain?.locations?.map((location) => location.listings)

			states?.forEach(state => {
				const first4ListingsPerState = state.slice(0, 4)
				first4ListingsPerState?.forEach((listing) => {
					Object.keys(listing?.settings)?.forEach((key) => {
						const listingHasService = Object.keys(serviceTypes).includes(key) && listing?.settings?.[key]
						if (listingHasService) serviceTypes[key] = true
					})
				})
			})
			const serviceTypesFiltered = Object.keys(serviceTypes).filter((key) => serviceTypes[key]).map((key) => services.find((service) => service[key]))
			const serviceTypesString = serviceTypesFiltered.map((service) => service[Object.keys(service)[0]]).join(' | ')
			return `View ${chainName}'s Dispensaries, Marijuana Menu, Daily Specials, Reviews, Photos, and more! ${chainName} Marijuana Dispensaries offering ${serviceTypesString} services in ${first3States} | Where’s Weed`
		}
	},
	methods: {
		handleFetchMore(state, offset) {
			this.$apollo.queries.chain.fetchMore({
				variables: {
					state: state,
					offset: offset,
					limit: SUBSEQUENT_FETCH_LIMIT
				},
				updateQuery: (previousResult, { fetchMoreResult }) => {
					const oldListings = previousResult.chains[0].locations.find(location => location.state === state).listings
					const newListings = fetchMoreResult.chains[0].locations.find(location => location.state === state).listings
					const updatedLocations = previousResult.chains[0].locations.map(location => location.state === state
						? {
							state: location.state,
							totalLocations: location.totalLocations,
							listings: [ ...oldListings, ...newListings ],
							__typename: location.__typename
						}
						: location)
					return {
						chains: [
							{
								__typename: previousResult.chains[0].__typename,
								about: previousResult.chains[0].about,
								contact: previousResult.chains[0].contact,
								facebook: previousResult.chains[0].facebook,
								faqs: previousResult.chains[0].faqs,
								id: previousResult.chains[0].id,
								instagram: previousResult.chains[0].instagram,
								jobBoard: previousResult.chains[0].jobBoard,
								linkedin: previousResult.chains[0].linkedin,
								locations: updatedLocations,
								coverImage: previousResult.chains[0].coverImage,
								logo: previousResult.chains[0].logo,
								metaDescription: previousResult.chains[0].metaDescription,
								metaTitle: previousResult.chains[0].metaTitle,
								name: previousResult.chains[0].name,
								numberOfListings: previousResult.chains[0].numberOfListings,
								shortName: previousResult.chains[0].shortName,
								twitter: previousResult.chains[0].twitter
							}
						]
					}
				}
			})
		}
	}
}
</script>
